import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.scss';
import Layout from './pages/Layout';
import { lazy, Suspense, useContext, useEffect } from 'react';
import Loading from './components/Loading/Loading';
import LoginProtector from './protector/LoginProtector';
import AuthLayout from './pages/Auth/AuthLayout';
import axios from 'axios';
import { BaseUrl } from './API/ApiHandle';
import { useCookies } from 'react-cookie';
import { ChartContext } from './Context/Charts';
import DataIdProtector from './protector/DataIdProtector';
import ShareLayout from './pages/Share/ShareLayout';

const Login = lazy(() => import("./pages/Auth/Login/Login"));
const Home = lazy(() => import("./pages/Home/Home"));
const SignUp = lazy(() => import("./pages/Auth/SignUp/SignUp"));
const Visualize = lazy(() => import("./pages/Visualize/Visualize"));
const Support = lazy(() => import("./pages/Support/Support"));
const Tables = lazy(() => import("./pages/Tables/Tables"));
const Share = lazy(() => import("./pages/Share/Share"));

function App() {

  const [Cookies,] = useCookies();
  const { setTableId, TableId } = useContext(ChartContext);

  const router = createBrowserRouter([
    {
      path: "", element: <Layout />, children: [
        {
          index: true, element:
            <LoginProtector>
              <Suspense fallback={<Loading />}><Home /></Suspense>
            </LoginProtector>
        },

        {
          path: 'Tables/:id', element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <DataIdProtector>
                <Tables />
              </DataIdProtector>
            </LoginProtector>
          </Suspense>
        },
        {
          path: 'Visualize', element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <DataIdProtector>
                <Visualize />
              </DataIdProtector>
            </LoginProtector>
          </Suspense>
        },
        {
          path: 'Support', element: <Suspense fallback={<Loading />}>
            <LoginProtector>
              <DataIdProtector>
                <Support />
              </DataIdProtector>
            </LoginProtector>
          </Suspense>
        },
      ],
    }, {
      path: "", element: <AuthLayout />, children: [
        { path: "Login", element: <Suspense fallback={<Loading />}><Login /></Suspense> },
        { path: "SignUp", element: <Suspense fallback={<Loading />}><SignUp /></Suspense> },
      ]
    }, {
      path: "", element: <ShareLayout />, children: [
        { path: "Charts/:file_id", element: <Suspense fallback={<Loading />}><Share /></Suspense> }
      ]
    }
  ])

  const GetTableID = async () => {
    try {
      const { data } = await axios.get(`${BaseUrl}/main/user_files`, {
        headers: {
          'Authorization': `Bearer ${Cookies.token}`
        }
      });
      if (data.files_info.length) {
        setTableId(data.files_info[0]._id);
      }
    } catch (error) {
      console.error('Error fetching table ID:', error);
    }
  };


  useEffect(() => {
    if (Cookies.token && !TableId) {
      GetTableID();
    }
  }, [Cookies.token, TableId]);

  // console.clear();

  return <RouterProvider router={router}>
  </RouterProvider>
}

export default App;
